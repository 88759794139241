:root {
    --light-regular-font-family: Montserrat, Verdana, sans-serif;
    --dark-regular-font-family: Oxygen-regular, Arial, sans-serif;
}

/* line 10, ../scss/components/_alertify.scss */
.alertify .ajs-modal {
    background: rgba(0, 0, 0, 0.54);
    z-index: 9999999;
}
/* line 16, ../scss/components/_alertify.scss */
.alertify .ajs-dialog {
    background-color: #32323F;
    width: 75%;
    max-width: 768px;
}

.popup-light.alertify .ajs-modal {
    background-color: rgba(200, 200, 200, 0.8);
}

body.theme-light .alertify .ajs-body .ajs-content,
body.theme-light .alertify .ajs-header,
body.theme-light .alertify .ajs-dialog,
.popup-light.alertify .ajs-body .ajs-content,
.popup-light.alertify .ajs-header,
.popup-light.alertify .ajs-dialog {
    color: #000;
    background: #fff;
}

body.admin .popup-light.alertify .ajs-body .ajs-content,
body.admin .popup-light.alertify .ajs-dialog {
    background-color: #fff;
}

body.admin .popup-light.alertify .ajs-header {
    color: #4a89dc;
    background-color: #fff;
}

body.admin .popup-light.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok,
body.admin .popup-light.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok:hover {
    background: #4a89dc;
    border-color: #4a89dc;
    color: #fff;
    font-family: var(--light-regular-font-family);
}

/* body.admin .popup-light.alertify .ajs-footer .ajs-buttons .ajs-button:hover,
body.admin .popup-light.alertify .ajs-footer .ajs-buttons .ajs-button {
    background: rgba(0,0,0,0.4);
    color: #fff;
} */

.popup-light.alertify .ajs-header {
    font-weight: 600;
}

.popup-light.alertify .ajs-footer .ajs-buttons .ajs-button:hover,
.popup-light.alertify .ajs-footer .ajs-buttons .ajs-button {
    background: #ddd;
    color: #000;
    font-family: var(--light-regular-font-family);
}

.popup-light.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok,
.popup-light.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok:hover {
    background: #4a89dc;
    border-color: #4a89dc;
    color: #fff;
    font-family: var(--light-regular-font-family);
}

/* line 24, ../scss/components/_alertify.scss */
.alertify .ajs-header {
    color: #ffeb3b;
    background: #32323F;
    border-bottom: 0;
    font-weight: normal;
    text-transform: uppercase;
    padding: 24px 24px 0;
    line-height: 32px;
}
/* line 34, ../scss/components/_alertify.scss */
.alertify .ajs-body {
    padding: 24px 0;
}
/* line 37, ../scss/components/_alertify.scss */
.alertify .ajs-body .ajs-content {
    line-height: 1.45;
    padding: 10px 0;
    color: #fff;
}
/* line 44, ../scss/components/_alertify.scss */
.alertify .ajs-content .list {
    list-style-position: inside;
}
/* line 49, ../scss/components/_alertify.scss */
.alertify .ajs-commands {
    margin-right: 10px;
}
/* line 53, ../scss/components/_alertify.scss */
.alertify button.ajs-close {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
/* line 56, ../scss/components/_alertify.scss */
.alertify button.ajs-close:hover {
    background-color: #222;
}
/* line 61, ../scss/components/_alertify.scss */
.alertify .ajs-footer {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    background: transparent;
    border-top: 0;
}
/* line 66, ../scss/components/_alertify.scss */
.alertify .ajs-footer .ajs-buttons .ajs-button {
    background: #484856;
    cursor: pointer;
    color: #fff;
    font-weight: normal;
    font-family: var(--dark-regular-font-family);
}
/* line 73, ../scss/components/_alertify.scss */
.alertify .ajs-footer .ajs-buttons .ajs-button:hover {
    background: rgb(60, 60, 60);
    font-family: var(--dark-regular-font-family);
}
/* line 77, ../scss/components/_alertify.scss */
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #000;
    background: rgb(249, 236, 51);
    border: 1px solid rgb(249, 236, 51);
    font-family: var(--dark-regular-font-family);
}
/* line 80, ../scss/components/_alertify.scss */
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok:hover {
    background: rgba(249, 236, 51, 0.3);
}
/* line 89, ../scss/components/_alertify.scss */
.alertify input[type="text"].ajs-input {
    background-color: #272731;
    color: #fff;
}
/* line 94, ../scss/components/_alertify.scss */
.alertify .ajs-body .ajs-content .ajs-input {
    border: 1px solid #777;
    font-size: 14px;
}

/* line 100, ../scss/components/_alertify.scss */

.alertify-notifier {
    z-index: 9999999;
}

.alertify-notifier .ajs-message.ajs-success {
    background: #2BAE66FF;
    border: 1px solid #2BAE66FF;
    color: #fff;
}

/* line 106, ../scss/components/_alertify.scss */
.alertify-notifier .ajs-message.ajs-error {
    background: #f12f2f;
    border: 1px solid #f00;
}
