.ag-theme-alpine {
    --ag-foreground-color: deeppink;
    --ag-header-column-separator-color: orange;
}

.ag-chart-menu-close,
.ag-chart-menu {
    position: absolute !important;
    opacity: 0 !important;
    width: 0;
    cursor: default;
    top: -10000pt !important;
    pointer-events: auto
}

.ag-chart-settings-nav-bar {
    opacity: 0 !important;
    pointer-events: none !important;
}

.ag-center-cols-container {
    width: auto;
    min-width: 100% !important;
}

.ag-header-cell-text {
    font-weight: 800;
}

.ag-theme-alpine-dark .ag-header-row.ag-header-row-column {
    background: #272730 !important;
}

.ag-theme-alpine .ag-sticky-top-container,
.ag-theme-alpine-dark .ag-sticky-top-container {
    width: 100%;
    min-width: calc(100% - 0px) !important;
}

.ag-theme-alpine-dark .ag-header {
    border-bottom: 2px solid rgb(249, 236, 51);
}

.ag-container {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.2);
}

.ag-chart-menu-panel [aria-label="Format"] {
    display: none !important;
}

.ag-theme-alpine-dark .ag-root-wrapper,
.ag-theme-alpine .ag-root-wrapper {
    border: none !important;
}

.ag-pinned-right-header,
.ag-pinned-left-header,
.ag-pinned-right-cols-container,
.ag-pinned-left-cols-container {
    border-right: 1px solid #e0e0e0 !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.ag-cell.ag-cell-last-left-pinned {
    border-right: none !important;
}

.ag-theme-alpine-dark .ag-pinned-right-header,
.ag-theme-alpine-dark .ag-pinned-left-header,
.ag-theme-alpine-dark .ag-pinned-right-cols-container,
.ag-theme-alpine-dark .ag-pinned-left-cols-container {
    border: 1px solid #1b1b21 !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.ag-row {
    font-size: 13.6px !important;
}

.ag-row, .ag-row-group {
    border: none !important;
}

/*.ag-header-row { height: 30px !important;}*/
.ag-row-group {
    height: 100%;
}

.ag-theme-alpine-dark {
    font-family: Oxygen-regular, Arial, sans-serif !important;
}

.ag-theme-alpine {
    font-family: Montserrat, Verdana, sans-serif !important;
}

.ag-row.ag-row-group .ag-cell-value[col-id="Meeting Date"],
.ag-row.ag-row-group .ag-cell-value[col-id="Report Date"],
.ag-row.ag-row-group .ag-cell-value[col-id="Opportunity Estimated End Date"],
.ag-row.ag-row-group .ag-cell-value[col-id="Opportunity Estimated Start Date"],
.ag-row.ag-row-group .ag-cell-value[col-id="Start Date"],
.ag-row.ag-row-group .ag-cell-value[col-id="Link Start Date"],
.ag-row.ag-row-group .ag-cell-value[col-id="Link End Date"],
.ag-row.ag-row-group .ag-cell-value[col-id="Interview Date"],
.ag-row.ag-row-group .ag-cell-value[col-id="Created Date"],
.ag-row.ag-row-group .ag-cell-value[col-id="End Date"],
.ag-row.ag-row-group .ag-cell-value[col-id="Kpi Date"] {
    opacity: 0 !important
}

.ag-theme-alpine .ag-header {
    background: #fff !important;
    border-bottom-color: #fff !important;
}

.ag-theme-alpine-dark .ag-ltr .ag-cell {
    border-right: 1px solid #1b1b21 !important;
}

.ag-theme-alpine .ag-row-even {
    background: #eef4fc !important;
}

.ag-theme-alpine .ag-row-odd {
    background: #fff !important;
}


.ag-theme-alpine-dark .ag-row-even {
    background: #32323E !important;
}

.ag-theme-alpine-dark .ag-header-viewport,
.ag-theme-alpine-dark .ag-header-container,
.ag-theme-alpine-dark .ag-center-cols-clipper,
.ag-theme-alpine-dark .ag-row-odd {
    background: #272730 !important;
}

.ag-theme-alpine-dark .ag-header-cell-text {
    font-size: 12px;
    color: #FFFFFF !important;
}

.ag-theme-alpine .ag-header-cell-text {
    color: #000;
    font-size: 12px;
    font-weight: 500 !important;
}

.ag-theme-alpine .ag-root-wrapper {
    margin: 0 10px;
    border: 1px solid #EDEBE9 !important;
    border-bottom: none !important;
}

.ag-theme-alpine [row-id*="GROUP"] .ag-group-value {
    font-weight: 500 !important;
}

.ag-theme-alpine-dark [row-id*="GROUP"] .ag-group-value {
    font-family: Oxygen-bold, Arial, sans-serif !important;
}

.ag-theme-alpine .ag-header-cell {
    border-right: 1px solid #EDEBE9;
}

.ag-header-cell {
    height: 34px !important;
}

.ag-cell,
.ag-header-cell {
    padding: 0 7px !important;
}

.ag-deep-linked-cell {
    cursor: pointer;
    color: var(--primary);
}

.ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total), .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
    border-top: none !important;
}

.ag-theme-alpine .ag-header-row {
    border-bottom: 1px solid #EDEBE9;
}

.ag-theme-dark .ag-header-row {
    border-bottom: 1px solid #1b1b21;
}

.skeleton {
    background-color: #EDEBE9;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    -webkit-animation: shine 1s ease infinite;
    animation: shine 1s ease infinite;
}

.ag-theme-alpine-dark .skeleton {
    background-color: #575766;
    background-image: linear-gradient(90deg, rgba(42, 42, 52, 0), rgba(42, 42, 52, 0.5), rgba(42, 42, 52, 0));
}

@-webkit-keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

.ag-theme-alpine-dark {
    --card: #272731;
    --paper: #32323f;
    --darkcard: #1b1b21;
    --primary: #f9ec33;
    --text: #ffffff;

    /*  Table  */
    --ag-header-height: 30px;
    --ag-foreground-color: var(--text);
    --ag-background-color: var(--card);
    --ag-header-foreground-color: var(--text);
    --ag-header-background-color: #272730;
    --ag-odd-row-background-color: #22222b;
    --ag-header-column-resize-handle-color: var(--card);

    --ag-alpine-active-color: var(--primary);
    --ag-row-hover-color: var(--darkcard);


    /* Borders */
    --ag-borders: 1px solid;
    --ag-border-color: var(--card);
    --ag-secondary-border-color: var(--paper);

    /* Forms */
    --ag-checkbox-checked-color: var(--primary);
    --ag-checkbox-unchecked-color: grey;
    --ag-checkbox-indeterminate-color: grey;

    /* Filter */
    --ag-control-panel-background-color: var(--darkcard);
    --ag-side-button-selected-background-color: var(--darkcard);

    --ag-selected-tab-underline-color: var(--primary);
    --ag-selected-tab-underline-width: 2px;
    --ag-selected-tab-underline-transition-speed: 0.2s;

    --ag-side-bar-panel-width: 300px;
    --ag-cell-horizontal-padding: 5px;
}

.ag-theme-alpine {
    --card: #ffffff;
    --paper: #eeeeee;
    --oddrow: #eef4fc;
    --darkcard: #fff;
    --primary: #4a89dc;
    --text: #333333;

    --ag-grid-size: 3px;
    --ag-row-height: 20px;
    /*  Table  */
    --ag-foreground-color: var(--text);
    --ag-background-color: var(--card);
    --ag-header-foreground-color: var(--text);
    --ag-header-background-color: var(--card);
    --ag-odd-row-background-color: var(--oddrow);
    --ag-header-column-resize-handle-color: var(--paper);

    --ag-alpine-active-color: var(--primary);
    --ag-row-hover-color: #e5edfa;

    /* Borders */
    --ag-borders: 1px solid;
    --ag-border-color: var(--card);
    --ag-secondary-border-color: var(--paper);
    --ag-row-border-color: transparent;

    /* Forms */
    --ag-checkbox-checked-color: var(--primary);
    --ag-checkbox-unchecked-color: grey;
    --ag-checkbox-indeterminate-color: grey;

    /* Filter */
    --ag-control-panel-background-color: var(--darkcard);
    --ag-side-button-selected-background-color: var(--darkcard);

    --ag-selected-tab-underline-color: var(--primary);
    --ag-selected-tab-underline-width: 2px;
    --ag-selected-tab-underline-transition-speed: 0.2s;

    --ag-side-bar-panel-width: 300px;
    --ag-cell-horizontal-padding: 5px;
}
