.input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #3f51b5;
    border: 1px solid #3f51b5;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem; }

.day-range-light .input-range__slider {
    background: rgb(74, 137, 220);
    border: 1px solid rgb(74, 137, 220);
    color: white;
}

.day-range-light .input-range__track {
    background: #ddd;
}

.day-range-light .input-range__label--value {
    color: #fff;
}

.day-range-light .input-range__track--active {
    background: rgb(74, 137, 220);
}

.input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
.input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }

.input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
    transform: none; }

.input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out; }

.input-range__label {
    color: #aaaaaa;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
    bottom: -1.4rem;
    position: absolute; }

.input-range__label--min {
    left: 0; }

.input-range__label--max {
    right: 0; }

.input-range__label--value {
    position: absolute;
    top: -1.8rem; }

.input-range__label-container {
    left: -50%;
    position: relative; }
.input-range__label--max .input-range__label-container {
    left: 50%; }

.input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out; }
.input-range--disabled .input-range__track {
    background: #eeeeee; }

.input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%; }

.input-range__track--active {
    background: #3f51b5; }

.input-range {
    height: 1rem;
    position: relative;
    width: 100%; }

/*# sourceMappingURL=react-input-range.css.map*/

.input-range__label--min, .input-range__label--max {
    display: none;
}

.input-range__slider {
    border: #f9ec33;
    background-color: #f9ec33;
}

.input-range__track--active {
    background-color: #f9ec33;
}

.input-range__label--value {
    top: -0.7rem;
    z-index: 999;
    font-size: 0.75rem;
    color: black;
    pointer-events: none
}

.input-range__slider {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: -0.75rem;
    margin-top: -0.9rem;
}

/* Datepicker */
/* Unfortunately after examining the sourcecode of rc-datepicker there is no
way of passing styling props directly to the floating datepicker, hence the need for these styles */
.position-datepicker-right .floating { right: 0; }
