
/* New curve chart */

.ba-progress-value {
    color: #000;
    font-weight: bold;
}

.ba-circle-outer {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #DA4453;
    margin: 0 auto;
    flex-shrink: 0;
}

/* ahead colors */

.ahead.ba-circle-outer {
    border-color: #44BB77;
}

.ahead .ba-progress-circle {
    background-color: #DDFFEF;
}

.ahead .ba-progress-circle .ba-progress-circle__overlay {
    background-color: #CAF1C9;
}

.ahead .ba-progress-circle .ba-progress-circle__slice__expected .ba-progress-circle__fill__expected,
.ahead .ba-progress-circle .ba-progress-circle__slice .ba-progress-circle__fill__expected {
    background: lightgreen;
}

.ahead .ba-progress-circle .ba-progress-circle__slice__expected .ba-progress-circle__fill,
.ahead .ba-progress-circle .ba-progress-circle__slice .ba-progress-circle__fill {
    background: #44BB77;
}


/* not set colours */

.notset.ba-circle-outer {
    border-color: #333;
}

.notset .ba-progress-circle {
    background-color: #ccc;
}

.notset .ba-progress-circle .ba-progress-circle__overlay {
    background-color: #fff;
}

.notset .ba-progress-circle .ba-progress-circle__slice__expected .ba-progress-circle__fill__expected,
.notset .ba-progress-circle .ba-progress-circle__slice .ba-progress-circle__fill__expected {
    background: #666;
}

.notset .ba-progress-circle .ba-progress-circle__slice__expected .ba-progress-circle__fill,
.notset .ba-progress-circle .ba-progress-circle__slice .ba-progress-circle__fill {
    background: #ccc;
}

/* ok colours */

.ok.ba-circle-outer {
    border-color: #F6A342;
}

.ok .ba-progress-circle {
    background-color: #FFE5D2;
}

.ok .ba-progress-circle .ba-progress-circle__overlay {
    background-color: #FDDDAB;
}

.ok .ba-progress-circle .ba-progress-circle__slice__expected .ba-progress-circle__fill__expected,
.ok .ba-progress-circle .ba-progress-circle__slice .ba-progress-circle__fill__expected {
    background: #FFD5B7;
}

.ok .ba-progress-circle .ba-progress-circle__slice__expected .ba-progress-circle__fill,
.ok .ba-progress-circle .ba-progress-circle__slice .ba-progress-circle__fill {
    background: #F6A342;
}

/* behind colors */
.behind.ba-circle-outer {
    border-color: #DA4453;
}

.behind .ba-progress-circle {
    background-color: #FFE3E3;
}

.behind .ba-progress-circle .ba-progress-circle__overlay {
    background-color: #FDCCC2;
}

.behind .ba-progress-circle .ba-progress-circle__slice__expected .ba-progress-circle__fill__expected,
.behind .ba-progress-circle .ba-progress-circle__slice .ba-progress-circle__fill__expected {
    background: #FAC3B8;
}

.behind .ba-progress-circle .ba-progress-circle__slice__expected .ba-progress-circle__fill,
.behind .ba-progress-circle .ba-progress-circle__slice .ba-progress-circle__fill {
    background: #DA4453;
}

.ba-progress-circle {
    border-radius: 50%;
}
.ba-progress-circle .ba-progress-circle__slice__expected,
.ba-progress-circle .ba-progress-circle__fill__expected,
.ba-progress-circle .ba-progress-circle__slice,
.ba-progress-circle .ba-progress-circle__fill {
    position: absolute;
    -webkit-backface-visibility: hidden;
    transition: transform 1s;
    border-radius: 50%;
}

.ba-progress-circle .ba-progress-circle__overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.ba-progress-circle[data-progress="0"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="0"] .ba-progress-circle__fill {
    transform: rotate(0deg);
}
.ba-progress-circle[data-progress="0"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(0deg);
}
.ba-progress-circle[data-progress="1"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="1"] .ba-progress-circle__fill {
    transform: rotate(1.8deg);
}
.ba-progress-circle[data-progress="1"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(3.6deg);
}
.ba-progress-circle[data-progress="2"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="2"] .ba-progress-circle__fill {
    transform: rotate(3.6deg);
}
.ba-progress-circle[data-progress="2"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(7.2deg);
}
.ba-progress-circle[data-progress="3"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="3"] .ba-progress-circle__fill {
    transform: rotate(5.4deg);
}
.ba-progress-circle[data-progress="3"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(10.8deg);
}
.ba-progress-circle[data-progress="4"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="4"] .ba-progress-circle__fill {
    transform: rotate(7.2deg);
}
.ba-progress-circle[data-progress="4"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(14.4deg);
}
.ba-progress-circle[data-progress="5"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="5"] .ba-progress-circle__fill {
    transform: rotate(9deg);
}
.ba-progress-circle[data-progress="5"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(18deg);
}
.ba-progress-circle[data-progress="6"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="6"] .ba-progress-circle__fill {
    transform: rotate(10.8deg);
}
.ba-progress-circle[data-progress="6"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(21.6deg);
}
.ba-progress-circle[data-progress="7"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="7"] .ba-progress-circle__fill {
    transform: rotate(12.6deg);
}
.ba-progress-circle[data-progress="7"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(25.2deg);
}
.ba-progress-circle[data-progress="8"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="8"] .ba-progress-circle__fill {
    transform: rotate(14.4deg);
}
.ba-progress-circle[data-progress="8"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(28.8deg);
}
.ba-progress-circle[data-progress="9"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="9"] .ba-progress-circle__fill {
    transform: rotate(16.2deg);
}
.ba-progress-circle[data-progress="9"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(32.4deg);
}
.ba-progress-circle[data-progress="10"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="10"] .ba-progress-circle__fill {
    transform: rotate(18deg);
}
.ba-progress-circle[data-progress="10"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(36deg);
}
.ba-progress-circle[data-progress="11"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="11"] .ba-progress-circle__fill {
    transform: rotate(19.8deg);
}
.ba-progress-circle[data-progress="11"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(39.6deg);
}
.ba-progress-circle[data-progress="12"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="12"] .ba-progress-circle__fill {
    transform: rotate(21.6deg);
}
.ba-progress-circle[data-progress="12"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(43.2deg);
}
.ba-progress-circle[data-progress="13"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="13"] .ba-progress-circle__fill {
    transform: rotate(23.4deg);
}
.ba-progress-circle[data-progress="13"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(46.8deg);
}
.ba-progress-circle[data-progress="14"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="14"] .ba-progress-circle__fill {
    transform: rotate(25.2deg);
}
.ba-progress-circle[data-progress="14"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(50.4deg);
}
.ba-progress-circle[data-progress="15"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="15"] .ba-progress-circle__fill {
    transform: rotate(27deg);
}
.ba-progress-circle[data-progress="15"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(54deg);
}
.ba-progress-circle[data-progress="16"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="16"] .ba-progress-circle__fill {
    transform: rotate(28.8deg);
}
.ba-progress-circle[data-progress="16"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(57.6deg);
}
.ba-progress-circle[data-progress="17"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="17"] .ba-progress-circle__fill {
    transform: rotate(30.6deg);
}
.ba-progress-circle[data-progress="17"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(61.2deg);
}
.ba-progress-circle[data-progress="18"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="18"] .ba-progress-circle__fill {
    transform: rotate(32.4deg);
}
.ba-progress-circle[data-progress="18"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(64.8deg);
}
.ba-progress-circle[data-progress="19"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="19"] .ba-progress-circle__fill {
    transform: rotate(34.2deg);
}
.ba-progress-circle[data-progress="19"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(68.4deg);
}
.ba-progress-circle[data-progress="20"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="20"] .ba-progress-circle__fill {
    transform: rotate(36deg);
}
.ba-progress-circle[data-progress="20"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(72deg);
}
.ba-progress-circle[data-progress="21"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="21"] .ba-progress-circle__fill {
    transform: rotate(37.8deg);
}
.ba-progress-circle[data-progress="21"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(75.6deg);
}
.ba-progress-circle[data-progress="22"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="22"] .ba-progress-circle__fill {
    transform: rotate(39.6deg);
}
.ba-progress-circle[data-progress="22"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(79.2deg);
}
.ba-progress-circle[data-progress="23"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="23"] .ba-progress-circle__fill {
    transform: rotate(41.4deg);
}
.ba-progress-circle[data-progress="23"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(82.8deg);
}
.ba-progress-circle[data-progress="24"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="24"] .ba-progress-circle__fill {
    transform: rotate(43.2deg);
}
.ba-progress-circle[data-progress="24"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(86.4deg);
}
.ba-progress-circle[data-progress="25"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="25"] .ba-progress-circle__fill {
    transform: rotate(45deg);
}
.ba-progress-circle[data-progress="25"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(90deg);
}
.ba-progress-circle[data-progress="26"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="26"] .ba-progress-circle__fill {
    transform: rotate(46.8deg);
}
.ba-progress-circle[data-progress="26"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(93.6deg);
}
.ba-progress-circle[data-progress="27"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="27"] .ba-progress-circle__fill {
    transform: rotate(48.6deg);
}
.ba-progress-circle[data-progress="27"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(97.2deg);
}
.ba-progress-circle[data-progress="28"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="28"] .ba-progress-circle__fill {
    transform: rotate(50.4deg);
}
.ba-progress-circle[data-progress="28"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(100.8deg);
}
.ba-progress-circle[data-progress="29"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="29"] .ba-progress-circle__fill {
    transform: rotate(52.2deg);
}
.ba-progress-circle[data-progress="29"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(104.4deg);
}
.ba-progress-circle[data-progress="30"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="30"] .ba-progress-circle__fill {
    transform: rotate(54deg);
}
.ba-progress-circle[data-progress="30"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(108deg);
}
.ba-progress-circle[data-progress="31"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="31"] .ba-progress-circle__fill {
    transform: rotate(55.8deg);
}
.ba-progress-circle[data-progress="31"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(111.6deg);
}
.ba-progress-circle[data-progress="32"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="32"] .ba-progress-circle__fill {
    transform: rotate(57.6deg);
}
.ba-progress-circle[data-progress="32"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(115.2deg);
}
.ba-progress-circle[data-progress="33"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="33"] .ba-progress-circle__fill {
    transform: rotate(59.4deg);
}
.ba-progress-circle[data-progress="33"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(118.8deg);
}
.ba-progress-circle[data-progress="34"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="34"] .ba-progress-circle__fill {
    transform: rotate(61.2deg);
}
.ba-progress-circle[data-progress="34"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(122.4deg);
}
.ba-progress-circle[data-progress="35"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="35"] .ba-progress-circle__fill {
    transform: rotate(63deg);
}
.ba-progress-circle[data-progress="35"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(126deg);
}
.ba-progress-circle[data-progress="36"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="36"] .ba-progress-circle__fill {
    transform: rotate(64.8deg);
}
.ba-progress-circle[data-progress="36"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(129.6deg);
}
.ba-progress-circle[data-progress="37"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="37"] .ba-progress-circle__fill {
    transform: rotate(66.6deg);
}
.ba-progress-circle[data-progress="37"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(133.2deg);
}
.ba-progress-circle[data-progress="38"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="38"] .ba-progress-circle__fill {
    transform: rotate(68.4deg);
}
.ba-progress-circle[data-progress="38"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(136.8deg);
}
.ba-progress-circle[data-progress="39"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="39"] .ba-progress-circle__fill {
    transform: rotate(70.2deg);
}
.ba-progress-circle[data-progress="39"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(140.4deg);
}
.ba-progress-circle[data-progress="40"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="40"] .ba-progress-circle__fill {
    transform: rotate(72deg);
}
.ba-progress-circle[data-progress="40"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(144deg);
}
.ba-progress-circle[data-progress="41"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="41"] .ba-progress-circle__fill {
    transform: rotate(73.8deg);
}
.ba-progress-circle[data-progress="41"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(147.6deg);
}
.ba-progress-circle[data-progress="42"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="42"] .ba-progress-circle__fill {
    transform: rotate(75.6deg);
}
.ba-progress-circle[data-progress="42"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(151.2deg);
}
.ba-progress-circle[data-progress="43"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="43"] .ba-progress-circle__fill {
    transform: rotate(77.4deg);
}
.ba-progress-circle[data-progress="43"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(154.8deg);
}
.ba-progress-circle[data-progress="44"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="44"] .ba-progress-circle__fill {
    transform: rotate(79.2deg);
}
.ba-progress-circle[data-progress="44"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(158.4deg);
}
.ba-progress-circle[data-progress="45"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="45"] .ba-progress-circle__fill {
    transform: rotate(81deg);
}
.ba-progress-circle[data-progress="45"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(162deg);
}
.ba-progress-circle[data-progress="46"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="46"] .ba-progress-circle__fill {
    transform: rotate(82.8deg);
}
.ba-progress-circle[data-progress="46"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(165.6deg);
}
.ba-progress-circle[data-progress="47"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="47"] .ba-progress-circle__fill {
    transform: rotate(84.6deg);
}
.ba-progress-circle[data-progress="47"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(169.2deg);
}
.ba-progress-circle[data-progress="48"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="48"] .ba-progress-circle__fill {
    transform: rotate(86.4deg);
}
.ba-progress-circle[data-progress="48"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(172.8deg);
}
.ba-progress-circle[data-progress="49"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="49"] .ba-progress-circle__fill {
    transform: rotate(88.2deg);
}
.ba-progress-circle[data-progress="49"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(176.4deg);
}
.ba-progress-circle[data-progress="50"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="50"] .ba-progress-circle__fill {
    transform: rotate(90deg);
}
.ba-progress-circle[data-progress="50"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(180deg);
}
.ba-progress-circle[data-progress="51"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="51"] .ba-progress-circle__fill {
    transform: rotate(91.8deg);
}
.ba-progress-circle[data-progress="51"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(183.6deg);
}
.ba-progress-circle[data-progress="52"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="52"] .ba-progress-circle__fill {
    transform: rotate(93.6deg);
}
.ba-progress-circle[data-progress="52"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(187.2deg);
}
.ba-progress-circle[data-progress="53"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="53"] .ba-progress-circle__fill {
    transform: rotate(95.4deg);
}
.ba-progress-circle[data-progress="53"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(190.8deg);
}
.ba-progress-circle[data-progress="54"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="54"] .ba-progress-circle__fill {
    transform: rotate(97.2deg);
}
.ba-progress-circle[data-progress="54"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(194.4deg);
}
.ba-progress-circle[data-progress="55"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="55"] .ba-progress-circle__fill {
    transform: rotate(99deg);
}
.ba-progress-circle[data-progress="55"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(198deg);
}
.ba-progress-circle[data-progress="56"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="56"] .ba-progress-circle__fill {
    transform: rotate(100.8deg);
}
.ba-progress-circle[data-progress="56"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(201.6deg);
}
.ba-progress-circle[data-progress="57"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="57"] .ba-progress-circle__fill {
    transform: rotate(102.6deg);
}
.ba-progress-circle[data-progress="57"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(205.2deg);
}
.ba-progress-circle[data-progress="58"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="58"] .ba-progress-circle__fill {
    transform: rotate(104.4deg);
}
.ba-progress-circle[data-progress="58"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(208.8deg);
}
.ba-progress-circle[data-progress="59"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="59"] .ba-progress-circle__fill {
    transform: rotate(106.2deg);
}
.ba-progress-circle[data-progress="59"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(212.4deg);
}
.ba-progress-circle[data-progress="60"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="60"] .ba-progress-circle__fill {
    transform: rotate(108deg);
}
.ba-progress-circle[data-progress="60"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(216deg);
}
.ba-progress-circle[data-progress="61"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="61"] .ba-progress-circle__fill {
    transform: rotate(109.8deg);
}
.ba-progress-circle[data-progress="61"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(219.6deg);
}
.ba-progress-circle[data-progress="62"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="62"] .ba-progress-circle__fill {
    transform: rotate(111.6deg);
}
.ba-progress-circle[data-progress="62"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(223.2deg);
}
.ba-progress-circle[data-progress="63"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="63"] .ba-progress-circle__fill {
    transform: rotate(113.4deg);
}
.ba-progress-circle[data-progress="63"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(226.8deg);
}
.ba-progress-circle[data-progress="64"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="64"] .ba-progress-circle__fill {
    transform: rotate(115.2deg);
}
.ba-progress-circle[data-progress="64"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(230.4deg);
}
.ba-progress-circle[data-progress="65"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="65"] .ba-progress-circle__fill {
    transform: rotate(117deg);
}
.ba-progress-circle[data-progress="65"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(234deg);
}
.ba-progress-circle[data-progress="66"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="66"] .ba-progress-circle__fill {
    transform: rotate(118.8deg);
}
.ba-progress-circle[data-progress="66"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(237.6deg);
}
.ba-progress-circle[data-progress="67"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="67"] .ba-progress-circle__fill {
    transform: rotate(120.6deg);
}
.ba-progress-circle[data-progress="67"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(241.2deg);
}
.ba-progress-circle[data-progress="68"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="68"] .ba-progress-circle__fill {
    transform: rotate(122.4deg);
}
.ba-progress-circle[data-progress="68"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(244.8deg);
}
.ba-progress-circle[data-progress="69"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="69"] .ba-progress-circle__fill {
    transform: rotate(124.2deg);
}
.ba-progress-circle[data-progress="69"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(248.4deg);
}
.ba-progress-circle[data-progress="70"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="70"] .ba-progress-circle__fill {
    transform: rotate(126deg);
}
.ba-progress-circle[data-progress="70"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(252deg);
}
.ba-progress-circle[data-progress="71"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="71"] .ba-progress-circle__fill {
    transform: rotate(127.8deg);
}
.ba-progress-circle[data-progress="71"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(255.6deg);
}
.ba-progress-circle[data-progress="72"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="72"] .ba-progress-circle__fill {
    transform: rotate(129.6deg);
}
.ba-progress-circle[data-progress="72"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(259.2deg);
}
.ba-progress-circle[data-progress="73"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="73"] .ba-progress-circle__fill {
    transform: rotate(131.4deg);
}
.ba-progress-circle[data-progress="73"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(262.8deg);
}
.ba-progress-circle[data-progress="74"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="74"] .ba-progress-circle__fill {
    transform: rotate(133.2deg);
}
.ba-progress-circle[data-progress="74"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(266.4deg);
}
.ba-progress-circle[data-progress="75"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="75"] .ba-progress-circle__fill {
    transform: rotate(135deg);
}
.ba-progress-circle[data-progress="75"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(270deg);
}
.ba-progress-circle[data-progress="76"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="76"] .ba-progress-circle__fill {
    transform: rotate(136.8deg);
}
.ba-progress-circle[data-progress="76"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(273.6deg);
}
.ba-progress-circle[data-progress="77"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="77"] .ba-progress-circle__fill {
    transform: rotate(138.6deg);
}
.ba-progress-circle[data-progress="77"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(277.2deg);
}
.ba-progress-circle[data-progress="78"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="78"] .ba-progress-circle__fill {
    transform: rotate(140.4deg);
}
.ba-progress-circle[data-progress="78"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(280.8deg);
}
.ba-progress-circle[data-progress="79"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="79"] .ba-progress-circle__fill {
    transform: rotate(142.2deg);
}
.ba-progress-circle[data-progress="79"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(284.4deg);
}
.ba-progress-circle[data-progress="80"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="80"] .ba-progress-circle__fill {
    transform: rotate(144deg);
}
.ba-progress-circle[data-progress="80"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(288deg);
}
.ba-progress-circle[data-progress="81"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="81"] .ba-progress-circle__fill {
    transform: rotate(145.8deg);
}
.ba-progress-circle[data-progress="81"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(291.6deg);
}
.ba-progress-circle[data-progress="82"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="82"] .ba-progress-circle__fill {
    transform: rotate(147.6deg);
}
.ba-progress-circle[data-progress="82"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(295.2deg);
}
.ba-progress-circle[data-progress="83"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="83"] .ba-progress-circle__fill {
    transform: rotate(149.4deg);
}
.ba-progress-circle[data-progress="83"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(298.8deg);
}
.ba-progress-circle[data-progress="84"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="84"] .ba-progress-circle__fill {
    transform: rotate(151.2deg);
}
.ba-progress-circle[data-progress="84"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(302.4deg);
}
.ba-progress-circle[data-progress="85"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="85"] .ba-progress-circle__fill {
    transform: rotate(153deg);
}
.ba-progress-circle[data-progress="85"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(306deg);
}
.ba-progress-circle[data-progress="86"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="86"] .ba-progress-circle__fill {
    transform: rotate(154.8deg);
}
.ba-progress-circle[data-progress="86"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(309.6deg);
}
.ba-progress-circle[data-progress="87"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="87"] .ba-progress-circle__fill {
    transform: rotate(156.6deg);
}
.ba-progress-circle[data-progress="87"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(313.2deg);
}
.ba-progress-circle[data-progress="88"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="88"] .ba-progress-circle__fill {
    transform: rotate(158.4deg);
}
.ba-progress-circle[data-progress="88"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(316.8deg);
}
.ba-progress-circle[data-progress="89"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="89"] .ba-progress-circle__fill {
    transform: rotate(160.2deg);
}
.ba-progress-circle[data-progress="89"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(320.4deg);
}
.ba-progress-circle[data-progress="90"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="90"] .ba-progress-circle__fill {
    transform: rotate(162deg);
}
.ba-progress-circle[data-progress="90"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(324deg);
}
.ba-progress-circle[data-progress="91"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="91"] .ba-progress-circle__fill {
    transform: rotate(163.8deg);
}
.ba-progress-circle[data-progress="91"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(327.6deg);
}
.ba-progress-circle[data-progress="92"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="92"] .ba-progress-circle__fill {
    transform: rotate(165.6deg);
}
.ba-progress-circle[data-progress="92"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(331.2deg);
}
.ba-progress-circle[data-progress="93"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="93"] .ba-progress-circle__fill {
    transform: rotate(167.4deg);
}
.ba-progress-circle[data-progress="93"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(334.8deg);
}
.ba-progress-circle[data-progress="94"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="94"] .ba-progress-circle__fill {
    transform: rotate(169.2deg);
}
.ba-progress-circle[data-progress="94"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(338.4deg);
}
.ba-progress-circle[data-progress="95"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="95"] .ba-progress-circle__fill {
    transform: rotate(171deg);
}
.ba-progress-circle[data-progress="95"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(342deg);
}
.ba-progress-circle[data-progress="96"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="96"] .ba-progress-circle__fill {
    transform: rotate(172.8deg);
}
.ba-progress-circle[data-progress="96"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(345.6deg);
}
.ba-progress-circle[data-progress="97"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="97"] .ba-progress-circle__fill {
    transform: rotate(174.6deg);
}
.ba-progress-circle[data-progress="97"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(349.2deg);
}
.ba-progress-circle[data-progress="98"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="98"] .ba-progress-circle__fill {
    transform: rotate(176.4deg);
}
.ba-progress-circle[data-progress="98"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(352.8deg);
}
.ba-progress-circle[data-progress="99"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="99"] .ba-progress-circle__fill {
    transform: rotate(178.2deg);
}
.ba-progress-circle[data-progress="99"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(356.4deg);
}
.ba-progress-circle[data-progress="100"] .ba-progress-circle__slice.full, .ba-progress-circle[data-progress="100"] .ba-progress-circle__fill {
    transform: rotate(180deg);
}
.ba-progress-circle[data-progress="100"] .ba-progress-circle__fill.ba-progress-circle__bar {
    transform: rotate(360deg);
}
.ba-progress-circle[data-expected="0"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="0"] .ba-progress-circle__fill__expected {
    transform: rotate(0deg);
}
.ba-progress-circle[data-expected="0"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(0deg);
}
.ba-progress-circle[data-expected="1"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="1"] .ba-progress-circle__fill__expected {
    transform: rotate(1.8deg);
}
.ba-progress-circle[data-expected="1"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(3.6deg);
}
.ba-progress-circle[data-expected="2"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="2"] .ba-progress-circle__fill__expected {
    transform: rotate(3.6deg);
}
.ba-progress-circle[data-expected="2"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(7.2deg);
}
.ba-progress-circle[data-expected="3"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="3"] .ba-progress-circle__fill__expected {
    transform: rotate(5.4deg);
}
.ba-progress-circle[data-expected="3"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(10.8deg);
}
.ba-progress-circle[data-expected="4"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="4"] .ba-progress-circle__fill__expected {
    transform: rotate(7.2deg);
}
.ba-progress-circle[data-expected="4"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(14.4deg);
}
.ba-progress-circle[data-expected="5"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="5"] .ba-progress-circle__fill__expected {
    transform: rotate(9deg);
}
.ba-progress-circle[data-expected="5"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(18deg);
}
.ba-progress-circle[data-expected="6"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="6"] .ba-progress-circle__fill__expected {
    transform: rotate(10.8deg);
}
.ba-progress-circle[data-expected="6"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(21.6deg);
}
.ba-progress-circle[data-expected="7"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="7"] .ba-progress-circle__fill__expected {
    transform: rotate(12.6deg);
}
.ba-progress-circle[data-expected="7"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(25.2deg);
}
.ba-progress-circle[data-expected="8"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="8"] .ba-progress-circle__fill__expected {
    transform: rotate(14.4deg);
}
.ba-progress-circle[data-expected="8"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(28.8deg);
}
.ba-progress-circle[data-expected="9"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="9"] .ba-progress-circle__fill__expected {
    transform: rotate(16.2deg);
}
.ba-progress-circle[data-expected="9"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(32.4deg);
}
.ba-progress-circle[data-expected="10"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="10"] .ba-progress-circle__fill__expected {
    transform: rotate(18deg);
}
.ba-progress-circle[data-expected="10"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(36deg);
}
.ba-progress-circle[data-expected="11"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="11"] .ba-progress-circle__fill__expected {
    transform: rotate(19.8deg);
}
.ba-progress-circle[data-expected="11"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(39.6deg);
}
.ba-progress-circle[data-expected="12"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="12"] .ba-progress-circle__fill__expected {
    transform: rotate(21.6deg);
}
.ba-progress-circle[data-expected="12"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(43.2deg);
}
.ba-progress-circle[data-expected="13"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="13"] .ba-progress-circle__fill__expected {
    transform: rotate(23.4deg);
}
.ba-progress-circle[data-expected="13"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(46.8deg);
}
.ba-progress-circle[data-expected="14"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="14"] .ba-progress-circle__fill__expected {
    transform: rotate(25.2deg);
}
.ba-progress-circle[data-expected="14"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(50.4deg);
}
.ba-progress-circle[data-expected="15"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="15"] .ba-progress-circle__fill__expected {
    transform: rotate(27deg);
}
.ba-progress-circle[data-expected="15"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(54deg);
}
.ba-progress-circle[data-expected="16"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="16"] .ba-progress-circle__fill__expected {
    transform: rotate(28.8deg);
}
.ba-progress-circle[data-expected="16"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(57.6deg);
}
.ba-progress-circle[data-expected="17"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="17"] .ba-progress-circle__fill__expected {
    transform: rotate(30.6deg);
}
.ba-progress-circle[data-expected="17"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(61.2deg);
}
.ba-progress-circle[data-expected="18"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="18"] .ba-progress-circle__fill__expected {
    transform: rotate(32.4deg);
}
.ba-progress-circle[data-expected="18"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(64.8deg);
}
.ba-progress-circle[data-expected="19"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="19"] .ba-progress-circle__fill__expected {
    transform: rotate(34.2deg);
}
.ba-progress-circle[data-expected="19"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(68.4deg);
}
.ba-progress-circle[data-expected="20"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="20"] .ba-progress-circle__fill__expected {
    transform: rotate(36deg);
}
.ba-progress-circle[data-expected="20"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(72deg);
}
.ba-progress-circle[data-expected="21"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="21"] .ba-progress-circle__fill__expected {
    transform: rotate(37.8deg);
}
.ba-progress-circle[data-expected="21"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(75.6deg);
}
.ba-progress-circle[data-expected="22"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="22"] .ba-progress-circle__fill__expected {
    transform: rotate(39.6deg);
}
.ba-progress-circle[data-expected="22"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(79.2deg);
}
.ba-progress-circle[data-expected="23"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="23"] .ba-progress-circle__fill__expected {
    transform: rotate(41.4deg);
}
.ba-progress-circle[data-expected="23"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(82.8deg);
}
.ba-progress-circle[data-expected="24"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="24"] .ba-progress-circle__fill__expected {
    transform: rotate(43.2deg);
}
.ba-progress-circle[data-expected="24"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(86.4deg);
}
.ba-progress-circle[data-expected="25"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="25"] .ba-progress-circle__fill__expected {
    transform: rotate(45deg);
}
.ba-progress-circle[data-expected="25"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(90deg);
}
.ba-progress-circle[data-expected="26"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="26"] .ba-progress-circle__fill__expected {
    transform: rotate(46.8deg);
}
.ba-progress-circle[data-expected="26"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(93.6deg);
}
.ba-progress-circle[data-expected="27"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="27"] .ba-progress-circle__fill__expected {
    transform: rotate(48.6deg);
}
.ba-progress-circle[data-expected="27"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(97.2deg);
}
.ba-progress-circle[data-expected="28"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="28"] .ba-progress-circle__fill__expected {
    transform: rotate(50.4deg);
}
.ba-progress-circle[data-expected="28"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(100.8deg);
}
.ba-progress-circle[data-expected="29"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="29"] .ba-progress-circle__fill__expected {
    transform: rotate(52.2deg);
}
.ba-progress-circle[data-expected="29"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(104.4deg);
}
.ba-progress-circle[data-expected="30"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="30"] .ba-progress-circle__fill__expected {
    transform: rotate(54deg);
}
.ba-progress-circle[data-expected="30"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(108deg);
}
.ba-progress-circle[data-expected="31"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="31"] .ba-progress-circle__fill__expected {
    transform: rotate(55.8deg);
}
.ba-progress-circle[data-expected="31"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(111.6deg);
}
.ba-progress-circle[data-expected="32"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="32"] .ba-progress-circle__fill__expected {
    transform: rotate(57.6deg);
}
.ba-progress-circle[data-expected="32"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(115.2deg);
}
.ba-progress-circle[data-expected="33"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="33"] .ba-progress-circle__fill__expected {
    transform: rotate(59.4deg);
}
.ba-progress-circle[data-expected="33"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(118.8deg);
}
.ba-progress-circle[data-expected="34"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="34"] .ba-progress-circle__fill__expected {
    transform: rotate(61.2deg);
}
.ba-progress-circle[data-expected="34"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(122.4deg);
}
.ba-progress-circle[data-expected="35"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="35"] .ba-progress-circle__fill__expected {
    transform: rotate(63deg);
}
.ba-progress-circle[data-expected="35"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(126deg);
}
.ba-progress-circle[data-expected="36"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="36"] .ba-progress-circle__fill__expected {
    transform: rotate(64.8deg);
}
.ba-progress-circle[data-expected="36"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(129.6deg);
}
.ba-progress-circle[data-expected="37"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="37"] .ba-progress-circle__fill__expected {
    transform: rotate(66.6deg);
}
.ba-progress-circle[data-expected="37"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(133.2deg);
}
.ba-progress-circle[data-expected="38"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="38"] .ba-progress-circle__fill__expected {
    transform: rotate(68.4deg);
}
.ba-progress-circle[data-expected="38"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(136.8deg);
}
.ba-progress-circle[data-expected="39"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="39"] .ba-progress-circle__fill__expected {
    transform: rotate(70.2deg);
}
.ba-progress-circle[data-expected="39"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(140.4deg);
}
.ba-progress-circle[data-expected="40"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="40"] .ba-progress-circle__fill__expected {
    transform: rotate(72deg);
}
.ba-progress-circle[data-expected="40"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(144deg);
}
.ba-progress-circle[data-expected="41"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="41"] .ba-progress-circle__fill__expected {
    transform: rotate(73.8deg);
}
.ba-progress-circle[data-expected="41"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(147.6deg);
}
.ba-progress-circle[data-expected="42"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="42"] .ba-progress-circle__fill__expected {
    transform: rotate(75.6deg);
}
.ba-progress-circle[data-expected="42"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(151.2deg);
}
.ba-progress-circle[data-expected="43"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="43"] .ba-progress-circle__fill__expected {
    transform: rotate(77.4deg);
}
.ba-progress-circle[data-expected="43"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(154.8deg);
}
.ba-progress-circle[data-expected="44"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="44"] .ba-progress-circle__fill__expected {
    transform: rotate(79.2deg);
}
.ba-progress-circle[data-expected="44"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(158.4deg);
}
.ba-progress-circle[data-expected="45"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="45"] .ba-progress-circle__fill__expected {
    transform: rotate(81deg);
}
.ba-progress-circle[data-expected="45"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(162deg);
}
.ba-progress-circle[data-expected="46"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="46"] .ba-progress-circle__fill__expected {
    transform: rotate(82.8deg);
}
.ba-progress-circle[data-expected="46"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(165.6deg);
}
.ba-progress-circle[data-expected="47"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="47"] .ba-progress-circle__fill__expected {
    transform: rotate(84.6deg);
}
.ba-progress-circle[data-expected="47"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(169.2deg);
}
.ba-progress-circle[data-expected="48"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="48"] .ba-progress-circle__fill__expected {
    transform: rotate(86.4deg);
}
.ba-progress-circle[data-expected="48"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(172.8deg);
}
.ba-progress-circle[data-expected="49"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="49"] .ba-progress-circle__fill__expected {
    transform: rotate(88.2deg);
}
.ba-progress-circle[data-expected="49"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(176.4deg);
}
.ba-progress-circle[data-expected="50"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="50"] .ba-progress-circle__fill__expected {
    transform: rotate(90deg);
}
.ba-progress-circle[data-expected="50"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(180deg);
}
.ba-progress-circle[data-expected="51"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="51"] .ba-progress-circle__fill__expected {
    transform: rotate(91.8deg);
}
.ba-progress-circle[data-expected="51"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(183.6deg);
}
.ba-progress-circle[data-expected="52"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="52"] .ba-progress-circle__fill__expected {
    transform: rotate(93.6deg);
}
.ba-progress-circle[data-expected="52"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(187.2deg);
}
.ba-progress-circle[data-expected="53"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="53"] .ba-progress-circle__fill__expected {
    transform: rotate(95.4deg);
}
.ba-progress-circle[data-expected="53"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(190.8deg);
}
.ba-progress-circle[data-expected="54"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="54"] .ba-progress-circle__fill__expected {
    transform: rotate(97.2deg);
}
.ba-progress-circle[data-expected="54"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(194.4deg);
}
.ba-progress-circle[data-expected="55"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="55"] .ba-progress-circle__fill__expected {
    transform: rotate(99deg);
}
.ba-progress-circle[data-expected="55"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(198deg);
}
.ba-progress-circle[data-expected="56"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="56"] .ba-progress-circle__fill__expected {
    transform: rotate(100.8deg);
}
.ba-progress-circle[data-expected="56"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(201.6deg);
}
.ba-progress-circle[data-expected="57"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="57"] .ba-progress-circle__fill__expected {
    transform: rotate(102.6deg);
}
.ba-progress-circle[data-expected="57"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(205.2deg);
}
.ba-progress-circle[data-expected="58"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="58"] .ba-progress-circle__fill__expected {
    transform: rotate(104.4deg);
}
.ba-progress-circle[data-expected="58"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(208.8deg);
}
.ba-progress-circle[data-expected="59"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="59"] .ba-progress-circle__fill__expected {
    transform: rotate(106.2deg);
}
.ba-progress-circle[data-expected="59"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(212.4deg);
}
.ba-progress-circle[data-expected="60"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="60"] .ba-progress-circle__fill__expected {
    transform: rotate(108deg);
}
.ba-progress-circle[data-expected="60"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(216deg);
}
.ba-progress-circle[data-expected="61"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="61"] .ba-progress-circle__fill__expected {
    transform: rotate(109.8deg);
}
.ba-progress-circle[data-expected="61"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(219.6deg);
}
.ba-progress-circle[data-expected="62"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="62"] .ba-progress-circle__fill__expected {
    transform: rotate(111.6deg);
}
.ba-progress-circle[data-expected="62"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(223.2deg);
}
.ba-progress-circle[data-expected="63"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="63"] .ba-progress-circle__fill__expected {
    transform: rotate(113.4deg);
}
.ba-progress-circle[data-expected="63"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(226.8deg);
}
.ba-progress-circle[data-expected="64"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="64"] .ba-progress-circle__fill__expected {
    transform: rotate(115.2deg);
}
.ba-progress-circle[data-expected="64"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(230.4deg);
}
.ba-progress-circle[data-expected="65"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="65"] .ba-progress-circle__fill__expected {
    transform: rotate(117deg);
}
.ba-progress-circle[data-expected="65"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(234deg);
}
.ba-progress-circle[data-expected="66"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="66"] .ba-progress-circle__fill__expected {
    transform: rotate(118.8deg);
}
.ba-progress-circle[data-expected="66"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(237.6deg);
}
.ba-progress-circle[data-expected="67"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="67"] .ba-progress-circle__fill__expected {
    transform: rotate(120.6deg);
}
.ba-progress-circle[data-expected="67"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(241.2deg);
}
.ba-progress-circle[data-expected="68"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="68"] .ba-progress-circle__fill__expected {
    transform: rotate(122.4deg);
}
.ba-progress-circle[data-expected="68"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(244.8deg);
}
.ba-progress-circle[data-expected="69"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="69"] .ba-progress-circle__fill__expected {
    transform: rotate(124.2deg);
}
.ba-progress-circle[data-expected="69"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(248.4deg);
}
.ba-progress-circle[data-expected="70"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="70"] .ba-progress-circle__fill__expected {
    transform: rotate(126deg);
}
.ba-progress-circle[data-expected="70"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(252deg);
}
.ba-progress-circle[data-expected="71"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="71"] .ba-progress-circle__fill__expected {
    transform: rotate(127.8deg);
}
.ba-progress-circle[data-expected="71"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(255.6deg);
}
.ba-progress-circle[data-expected="72"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="72"] .ba-progress-circle__fill__expected {
    transform: rotate(129.6deg);
}
.ba-progress-circle[data-expected="72"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(259.2deg);
}
.ba-progress-circle[data-expected="73"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="73"] .ba-progress-circle__fill__expected {
    transform: rotate(131.4deg);
}
.ba-progress-circle[data-expected="73"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(262.8deg);
}
.ba-progress-circle[data-expected="74"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="74"] .ba-progress-circle__fill__expected {
    transform: rotate(133.2deg);
}
.ba-progress-circle[data-expected="74"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(266.4deg);
}
.ba-progress-circle[data-expected="75"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="75"] .ba-progress-circle__fill__expected {
    transform: rotate(135deg);
}
.ba-progress-circle[data-expected="75"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(270deg);
}
.ba-progress-circle[data-expected="76"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="76"] .ba-progress-circle__fill__expected {
    transform: rotate(136.8deg);
}
.ba-progress-circle[data-expected="76"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(273.6deg);
}
.ba-progress-circle[data-expected="77"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="77"] .ba-progress-circle__fill__expected {
    transform: rotate(138.6deg);
}
.ba-progress-circle[data-expected="77"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(277.2deg);
}
.ba-progress-circle[data-expected="78"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="78"] .ba-progress-circle__fill__expected {
    transform: rotate(140.4deg);
}
.ba-progress-circle[data-expected="78"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(280.8deg);
}
.ba-progress-circle[data-expected="79"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="79"] .ba-progress-circle__fill__expected {
    transform: rotate(142.2deg);
}
.ba-progress-circle[data-expected="79"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(284.4deg);
}
.ba-progress-circle[data-expected="80"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="80"] .ba-progress-circle__fill__expected {
    transform: rotate(144deg);
}
.ba-progress-circle[data-expected="80"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(288deg);
}
.ba-progress-circle[data-expected="81"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="81"] .ba-progress-circle__fill__expected {
    transform: rotate(145.8deg);
}
.ba-progress-circle[data-expected="81"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(291.6deg);
}
.ba-progress-circle[data-expected="82"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="82"] .ba-progress-circle__fill__expected {
    transform: rotate(147.6deg);
}
.ba-progress-circle[data-expected="82"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(295.2deg);
}
.ba-progress-circle[data-expected="83"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="83"] .ba-progress-circle__fill__expected {
    transform: rotate(149.4deg);
}
.ba-progress-circle[data-expected="83"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(298.8deg);
}
.ba-progress-circle[data-expected="84"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="84"] .ba-progress-circle__fill__expected {
    transform: rotate(151.2deg);
}
.ba-progress-circle[data-expected="84"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(302.4deg);
}
.ba-progress-circle[data-expected="85"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="85"] .ba-progress-circle__fill__expected {
    transform: rotate(153deg);
}
.ba-progress-circle[data-expected="85"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(306deg);
}
.ba-progress-circle[data-expected="86"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="86"] .ba-progress-circle__fill__expected {
    transform: rotate(154.8deg);
}
.ba-progress-circle[data-expected="86"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(309.6deg);
}
.ba-progress-circle[data-expected="87"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="87"] .ba-progress-circle__fill__expected {
    transform: rotate(156.6deg);
}
.ba-progress-circle[data-expected="87"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(313.2deg);
}
.ba-progress-circle[data-expected="88"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="88"] .ba-progress-circle__fill__expected {
    transform: rotate(158.4deg);
}
.ba-progress-circle[data-expected="88"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(316.8deg);
}
.ba-progress-circle[data-expected="89"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="89"] .ba-progress-circle__fill__expected {
    transform: rotate(160.2deg);
}
.ba-progress-circle[data-expected="89"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(320.4deg);
}
.ba-progress-circle[data-expected="90"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="90"] .ba-progress-circle__fill__expected {
    transform: rotate(162deg);
}
.ba-progress-circle[data-expected="90"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(324deg);
}
.ba-progress-circle[data-expected="91"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="91"] .ba-progress-circle__fill__expected {
    transform: rotate(163.8deg);
}
.ba-progress-circle[data-expected="91"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(327.6deg);
}
.ba-progress-circle[data-expected="92"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="92"] .ba-progress-circle__fill__expected {
    transform: rotate(165.6deg);
}
.ba-progress-circle[data-expected="92"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(331.2deg);
}
.ba-progress-circle[data-expected="93"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="93"] .ba-progress-circle__fill__expected {
    transform: rotate(167.4deg);
}
.ba-progress-circle[data-expected="93"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(334.8deg);
}
.ba-progress-circle[data-expected="94"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="94"] .ba-progress-circle__fill__expected {
    transform: rotate(169.2deg);
}
.ba-progress-circle[data-expected="94"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(338.4deg);
}
.ba-progress-circle[data-expected="95"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="95"] .ba-progress-circle__fill__expected {
    transform: rotate(171deg);
}
.ba-progress-circle[data-expected="95"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(342deg);
}
.ba-progress-circle[data-expected="96"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="96"] .ba-progress-circle__fill__expected {
    transform: rotate(172.8deg);
}
.ba-progress-circle[data-expected="96"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(345.6deg);
}
.ba-progress-circle[data-expected="97"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="97"] .ba-progress-circle__fill__expected {
    transform: rotate(174.6deg);
}
.ba-progress-circle[data-expected="97"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(349.2deg);
}
.ba-progress-circle[data-expected="98"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="98"] .ba-progress-circle__fill__expected {
    transform: rotate(176.4deg);
}
.ba-progress-circle[data-expected="98"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(352.8deg);
}
.ba-progress-circle[data-expected="99"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="99"] .ba-progress-circle__fill__expected {
    transform: rotate(178.2deg);
}
.ba-progress-circle[data-expected="99"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(356.4deg);
}
.ba-progress-circle[data-expected="100"] .ba-progress-circle__slice__expected.full, .ba-progress-circle[data-expected="100"] .ba-progress-circle__fill__expected {
    transform: rotate(180deg);
}
.ba-progress-circle[data-expected="100"] .ba-progress-circle__fill__expected.ba-progress-circle__bar__expected {
    transform: rotate(360deg);
}