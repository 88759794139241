/**
 * FixedDataTable v1.0.2
 *
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 */

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableCellGroupLayout
 */

.fixedDataTableCellGroupLayout_cellGroup {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.fixedDataTable_isRTL .fixedDataTableCellGroupLayout_cellGroup {
    right: 0;
    left: auto;
}

.fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
}

.fixedDataTableCellGroupLayout_cellGroupWrapper {
    position: absolute;
    top: 0;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableCellLayout
 */

.fixedDataTableCellLayout_main {
    border-right-style: solid;
    border-right-width: 1px;
    border-width: 0 1px 0 0;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    position: absolute;
    white-space: normal;
}

.fixedDataTable_isRTL .fixedDataTableCellLayout_main {
    border-right-width: 0;
    border-left-style: solid;
    border-left-width: 1px;
}

.fixedDataTableCellLayout_lastChild {
    border-width: 0 1px 1px 0;
}

.fixedDataTableCellLayout_alignRight {
    text-align: right;
}

.fixedDataTableCellLayout_alignCenter {
    text-align: center;
}

.fixedDataTableCellLayout_wrap1 {
    display: table;
}

.fixedDataTableCellLayout_wrap2 {
    display: table-row;
}

.fixedDataTableCellLayout_wrap3 {
    display: table-cell;
    vertical-align: middle;
}

.fixedDataTableCellLayout_columnResizerContainer {
    position: absolute;
    right: 0px;
    width: 6px;
    z-index: 1;
}

.fixedDataTable_isRTL .fixedDataTableCellLayout_columnResizerContainer {
    left: 0px;
    right: auto;
}

.fixedDataTableCellLayout_columnResizerContainer:hover {
    cursor: ew-resize;
}

.fixedDataTableCellLayout_columnResizerContainer:hover .fixedDataTableCellLayout_columnResizerKnob {
    visibility: visible;
}

.fixedDataTableCellLayout_columnResizerKnob {
    position: absolute;
    right: 0px;
    visibility: hidden;
    width: 4px;
}

.fixedDataTable_isRTL .fixedDataTableCellLayout_columnResizerKnob {
    left: 0px;
    right: auto;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableColumnResizerLineLayout
 */

.fixedDataTableColumnResizerLineLayout_mouseArea {
    cursor: ew-resize;
    position: absolute;
    right: -5px;
    width: 12px;
}

.fixedDataTable_isRTL .fixedDataTableColumnResizerLineLayout_mouseArea  {
    right: auto;
    left: -5px;
}

.fixedDataTableColumnResizerLineLayout_main {
    border-right-style: solid;
    border-right-width: 1px;
    box-sizing: border-box;
    position: absolute;
    z-index: 10;
    pointer-events: none;
}

.fixedDataTable_isRTL .fixedDataTableColumnResizerLineLayout_main {
    border-right-width: 0;
    border-left-style: solid;
    border-left-width: 1px;
}

.fixedDataTableColumnResizerLineLayout_hiddenElem {
    display: none !important;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableLayout
 */

.fixedDataTableLayout_main {
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.fixedDataTableLayout_header,
.fixedDataTableLayout_hasBottomBorder {
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.fixedDataTableLayout_footer .public_fixedDataTableCell_main {
    border-top-style: solid;
    border-top-width: 1px;
}

.fixedDataTableLayout_topShadow,
.fixedDataTableLayout_bottomShadow {
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
}

.fixedDataTableLayout_bottomShadow {
    margin-top: -4px;
}

.fixedDataTableLayout_rowsContainer {
    overflow: hidden;
    position: relative;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableRowLayout
 */

.fixedDataTableRowLayout_main {
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    top: 0;
}

.fixedDataTableRowLayout_body {
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
}

.fixedDataTableRowLayout_rowExpanded {
    box-sizing: border-box;
    left: 0;
    position: absolute;
}

.fixedDataTableRowLayout_fixedColumnsDivider {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-left-style: solid;
    border-left-width: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
}

.fixedDataTable_isRTL .fixedDataTableRowLayout_fixedColumnsDivider {
    border-left-width: 0;
    border-right-style: solid;
    border-right-width: 1px;
}

.fixedDataTableRowLayout_columnsShadow {
    position: absolute;
    width: 4px;
}

.fixedDataTableRowLayout_columnsRightShadow {
    right: 1px;
}

.fixedDataTable_isRTL .fixedDataTableRowLayout_columnsRightShadow {
    left: 1px;
    right: auto;
}

.fixedDataTableRowLayout_rowWrapper {
    position: absolute;
    top: 0;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule ScrollbarLayout
 */

.ScrollbarLayout_main {
    box-sizing: border-box;
    outline: none;
    overflow: hidden;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ScrollbarLayout_mainVertical {
    bottom: 0;
    right: 0;
    top: 0;
    width: 15px;
}

.ScrollbarLayout_mainHorizontal {
    height: 15px;
    left: 0;
    transition-property: background-color height;
}

/* Touching the scroll-track directly makes the scroll-track bolder */
.ScrollbarLayout_mainHorizontal.public_Scrollbar_mainActive,
.ScrollbarLayout_mainHorizontal:hover {
    height: 17px;
}

.ScrollbarLayout_face {
    left: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    transition-duration: 250ms;
    transition-timing-function: ease;
    transition-property: width;
}

/**
 * This selector renders the "nub" of the scrollface. The nub must
 * be rendered as pseudo-element so that it won't receive any UI events then
 * we can get the correct `event.offsetX` and `event.offsetY` from the
 * scrollface element while dragging it.
 */
.ScrollbarLayout_face:after {
    border-radius: 6px;
    content: '';
    display: block;
    position: absolute;
    transition: background-color 250ms ease;
}

.ScrollbarLayout_faceHorizontal {
    bottom: 0;
    left: 0;
    top: 0;
}

.ScrollbarLayout_faceHorizontal:after {
    bottom: 4px;
    left: 0;
    top: 4px;
    width: 100%;
}

.fixedDataTable_isRTL .ScrollbarLayout_faceHorizontal,
.fixedDataTable_isRTL .ScrollbarLayout_faceHorizontal:after {
    right: 0;
    left: auto;
}

.ScrollbarLayout_faceHorizontal.public_Scrollbar_faceActive:after,
.ScrollbarLayout_main:hover .ScrollbarLayout_faceHorizontal:after {
    bottom: calc(4px/2);
}

.ScrollbarLayout_faceVertical {
    left: 0;
    right: 0;
    top: 0;
}

.ScrollbarLayout_faceVertical:after {
    height: 100%;
    left: 4px;
    right: 4px;
    top: 0;
}

.ScrollbarLayout_main:hover .ScrollbarLayout_faceVertical:after,
.ScrollbarLayout_faceVertical.public_Scrollbar_faceActive:after {
    left: calc(4px/2);
    right: calc(4px/2);
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTable
 *
 */

/**
 * Table.
 */
.public_fixedDataTable_main {
    border: none;
    font-size: 13.6px;
}

.table-dark .public_fixedDataTable_header {
    border-color: #f9ec33;
}

.table-dark .public_fixedDataTable_hasBottomBorder {
    border-bottom-color: rgba(0,0,0,0.5);
}

.public_fixedDataTable_header .public_fixedDataTableCell_main {
    font-weight: bold;
}

.public_fixedDataTable_header,
.public_fixedDataTable_scrollbarSpacer,
.public_fixedDataTable_header .public_fixedDataTableCell_main {
    background-color: #272731;
    background-image: none;
    font-size: 0.75rem;
    border-right: 0;
    color: #999;
    /*background-image: linear-gradient(#fff, #efefef);*/
}

.public_fixedDataTable_scrollbarSpacer {
    position: absolute;
    z-index: 99;
    top: 0;
}

/*.public_fixedDataTable_footer .public_fixedDataTableCell_main {*/
/*    background-color: green;*/
/*    border-color: deeppink;*/
/*}*/

.public_fixedDataTable_topShadow {
    background-image: linear-gradient(180deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
}

.public_fixedDataTable_bottomShadow {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
}

/*.public_fixedDataTable_horizontalScrollbar .public_Scrollbar_mainHorizontal {*/
/*    background-color: green;*/
/*}*/

.public_fixedDataTableCell_main .fixedDataTableCellLayout_main {
    border-color: #111 !important;
    background-color: #00000e !important;
}

.table-light .public_fixedDataTableCell_main {
    border:none;
}

.cell-even {
    background: #32323f !important;
}

.cell-odd {
    background: #272731 !important;
}

.table-light .cell-even {
    background: #eef4fc !important;
}

.table-light .cell-odd {
    background: #fff !important;
}

.table-light .public_fixedDataTableRow_main {
    background-color: transparent !important;
}

.public_Scrollbar_main.ScrollbarLayout_mainVertical {
    background: rgba(0,0,0,0);
    border-left: none;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableCell
 */

/**
 * Table cell.
 */
.public_fixedDataTableCell_main {
    background-color: #32323F;
    border-color: #484856;
}

.public_fixedDataTableCell_highlighted {
    background-color: purple;
}

.public_fixedDataTableCell_cellContent {
    padding: 2px 8px;
    cursor: default;
}

.public_fixedDataTableCell_columnResizerKnob {
    background-color: #0284ff;
}
.public_fixedDataTableCell_hasReorderHandle .public_fixedDataTableCell_cellContent {
    margin-left: 12px;
}

.fixedDataTable_isRTL .public_fixedDataTableCell_hasReorderHandle .public_fixedDataTableCell_cellContent {
    margin-left: auto;
    margin-right: 12px;
}
/**
 * Column reorder goodies.
 */
.fixedDataTableCellLayout_columnReorderContainer {
    border-color: #0284ff;
    background-color: rgba(0,0,0,0.1);
    width: 12px;
    margin-right: -12px;
    float: left;
    cursor: move;
}

.fixedDataTable_isRTL .fixedDataTableCellLayout_columnReorderContainer {
    margin-right: auto;
    margin-left: -12px;
    float: right;
}

.fixedDataTableCellLayout_columnReorderContainer:after {
    content: '::';
    position: absolute;
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
}

.fixedDataTable_isRTL .fixedDataTableCellLayout_columnReorderContainer:after {
    left: auto;
    right: 1px;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableColumnResizerLine
 *
 */

/**
 * Column resizer line.
 */
.public_fixedDataTableColumnResizerLine_main {
    border-color: #0284ff;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableRow
 */

/**
 * Table row.
 */
.public_fixedDataTableRow_main {
    background-color: #fff;
}

.public_fixedDataTableRow_highlighted,
.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: #0000000f;
}

.public_fixedDataTableRow_fixedColumnsDivider {
    border-color: #d3d3d3;
}

.public_fixedDataTableRow_columnsShadow {
    background-image: linear-gradient(90deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
}

.fixedDataTable_isRTL .public_fixedDataTableRow_columnsShadow {
    background-image: linear-gradient(270deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
}

.public_fixedDataTableRow_columnsRightShadow {
    transform: rotate(180deg);
}

.public_fixedDataTableRow_main {
    background-color: #2C2C2C;
    font-weight: normal;
    font-size: 0.85rem
}

.public_Scrollbar_main.ScrollbarLayout_mainVertical {
    background-color: transparent;
    border-left: 0
}

.public_Scrollbar_main.ScrollbarLayout_mainVertical:hover {
    background-color: #fff;
    border-left: 1px solid #d3d3d3
}

.public_fixedDataTable_scrollbarSpacer {
    background-image: none;
    background-color: transparent
}

.public_fixedDataTable_header,.public_fixedDataTable_header .public_fixedDataTableCell_main {
    background-image: none;
    font-size: 0.75rem;
    border-right: 0;
    color: #999;
}

.table-light .public_fixedDataTable_header, .table-light .public_fixedDataTable_header .public_fixedDataTableCell_main {
    background: white !important;
}

/*.public_fixedDataTable_main,.public_fixedDataTable_header,.public_fixedDataTable_hasBottomBorder {*/
/*    border-color: transparent;*/
/*    border-top: 0;*/
/*    border-left: 0*/
/*}*/

.table-dark .public_fixedDataTable_header {
    /* border-bottom: 2px solid #f9ec33 !important; */
}

.public_fixedDataTableCell_main {
    background-color: #33323f;
    border-color: #111
}

.public_fixedDataTableRow_highlighted,.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: rgba(0,0,0,0.06)
}

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule Scrollbar
 *
 */

/**
 * Scrollbars.
 */

/* Touching the scroll-track directly makes the scroll-track bolder */
.public_Scrollbar_main.public_Scrollbar_mainActive,
.public_Scrollbar_main {
    background-color: #fff;
    border-left: 1px solid #d3d3d3;
}

.fixedDataTable_isRTL .public_Scrollbar_main.public_Scrollbar_mainActive,
.fixedDataTable_isRTL .public_Scrollbar_main {
    border-right: 1px solid #d3d3d3;
    border-left-width: 0;
}

.public_Scrollbar_mainOpaque,
.public_Scrollbar_mainOpaque.public_Scrollbar_mainActive,
.public_Scrollbar_mainOpaque:hover {
    background-color: #fff;
}

.public_Scrollbar_face:after {
    background-color: #c2c2c2;
}

.public_Scrollbar_main:hover .public_Scrollbar_face:after,
.public_Scrollbar_mainActive .public_Scrollbar_face:after,
.public_Scrollbar_faceActive:after {
    background-color: #7d7d7d;
}


/* line 4, ../scss/components/_simple-data-table.scss */
.public_fixedDataTableRow_main {
    background-color: #2C2C2C;
    font-weight: normal;
    font-size: 0.85rem;
}

.table-light .public_fixedDataTableRow_main {
    font-family: Montserrat, Verdana, sans-serif;
}

.public_Scrollbar_main.ScrollbarLayout_mainVertical {
    background-color: transparent;
    border-left: 0;
}

.theme-dark .public_Scrollbar_main.ScrollbarLayout_mainVertical {
    background-color: #272731;
}

.public_Scrollbar_main.ScrollbarLayout_mainVertical:hover {
    background-color: #fff;
    border-left: 1px solid #d3d3d3;
}

/* fix for white bar appearing on vertical scrolls, change color as required*/
.public_fixedDataTable_scrollbarSpacer {
    background-image: none;
    background-color: transparent;
}

/* line 11, ../scss/components/_simple-data-table.scss */
.public_fixedDataTable_header,
.public_fixedDataTable_header .public_fixedDataTableCell_main {
    background-color: #272731;
    background-image: none;
    font-size: 0.75rem;
    border-right: 0;
    color: #757575;
}

/* line 20, ../scss/components/_simple-data-table.scss */
.public_fixedDataTable_main,
.public_fixedDataTable_hasBottomBorder {
    border-color: rgba(0,0,0,0);
    border-top: 0;
    border-left: 0;
}

/* line 28, ../scss/components/_simple-data-table.scss */
.table-dark .public_fixedDataTable_header {
    /* border-bottom: 2px solid #fff; */
}

.table-light .public_fixedDataTable_header {
    border: 1px solid #fff;
}

/* line 33, ../scss/components/_simple-data-table.scss */
.public_fixedDataTableCell_main {
    background-color: #33323f;
    border-color: #111;
}

/* line 38, ../scss/components/_simple-data-table.scss */
.public_fixedDataTableRow_highlighted,
.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: rgba(0,0,0,0.06);
}
